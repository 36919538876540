<template>
  <v-app style="background: none;">
    <div>
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <v-container fluid>
              <div class="mx-4 mt-5"><h5>Products</h5></div>

              <div class="row">
                <div class="col-lg-6">
                  <v-card>
                    <v-toolbar flat color="indigo" dark>
                      <v-toolbar-title> Channel </v-toolbar-title>

                      <v-spacer></v-spacer>

                      <v-select
                        v-model="activeChannel"
                        :items="channels"
                        item-text="channel.name"
                        item-value="channel.id"
                        @change="channelChange()"
                      >
                      </v-select>
                    </v-toolbar>

                    <v-treeview
                      v-if="showTreeview"
                      style="min-height: 600px;"
                      :items="items"
                      activatable
                      item-key="id"
                      color="primary"
                      @update:active="onUpdate"
                    >
                      <template #prepend="{ item }">
                        <v-icon v-if="!item.file">
                          {{ open ? "mdi-folder-open" : "mdi-folder" }}
                        </v-icon>
                        <v-icon v-else>
                          {{ files[item.file] }}
                        </v-icon>
                      </template>
                    </v-treeview>
                  </v-card>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-8">
                  <v-tabs horizontal @change="tabChange">
                    <v-tab>
                      All Products
                    </v-tab>
                    <v-tab>
                      Products in category
                    </v-tab>
                    <v-tab-item>
                      <v-tabs class="mt-4" horizontal>
                        <v-tab>
                          <v-icon left>mdi-file</v-icon>
                          Cards
                        </v-tab>
                        <v-tab>
                          <v-icon left>mdi-file</v-icon>
                          List
                        </v-tab>
                        <v-tab-item>
                          <v-row dense class="mx-4">
                            <v-col
                              v-for="(item, index) in paginatedProducts"
                              :key="index"
                              :cols="4"
                            >
                              <v-card class="h-100 mt-3 mb-3">
                                <v-img
                                  :src="
                                    item.main_image != null
                                      ? baseurl + item.main_image.url
                                      : '/media/product-channel.jpg'
                                  "
                                  gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                                  height="200px"
                                >
                                </v-img>
                                <v-card-text class="text--primary">
                                  {{ item.name }}
                                </v-card-text>

                                <v-card-actions>
                                  Product : {{ item.part_id }}
                                  <v-spacer> </v-spacer>
                                  <v-btn
                                    @click="
                                      $router.push(
                                        '/pim/product-view/' + item.id
                                      )
                                    "
                                  >
                                    View
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-col>
                          </v-row>
                          <div class="row">
                            <b-pagination
                              v-model="productcurrentPage"
                              :total-rows="producttotalCount"
                              :per-page="productlimit"
                              style="margin: 30px auto;"
                            ></b-pagination>
                          </div>
                        </v-tab-item>
                        <v-tab-item>
                          <v-row>
                            <v-spacer></v-spacer>
                            <v-col cols="6">
                              <v-text-field
                                v-model="tablesearch"
                                append-icon="mdi-magnify"
                                label="Search"
                                single-line
                                hide-details
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-data-table
                            :headers="headers"
                            :items="products"
                            :items-per-page="10"
                            :search="tablesearch"
                          >
                            <template #item="row">
                              <tr>
                                <td>{{ row.index + 1 }}</td>
                                <td>{{ row.item.name }}</td>
                                <td>{{ row.item.part_id }}</td>
                                <td>
                                  <v-btn
                                    class="ma-2"
                                    tile
                                    outlined
                                    color="success"
                                    @click="
                                      $router.push(
                                        '/pim/product-view/' + row.item.id
                                      )
                                    "
                                  >
                                    View
                                  </v-btn>
                                </td>
                              </tr>
                            </template>
                          </v-data-table>
                        </v-tab-item>
                      </v-tabs>
                    </v-tab-item>
                    <v-tab-item>
                      <v-tabs class="mt-4" horizontal>
                        <v-tab>
                          <v-icon left>mdi-file</v-icon>
                          Cards
                        </v-tab>
                        <v-tab>
                          <v-icon left>mdi-file</v-icon>
                          List
                        </v-tab>
                        <v-tab-item>
                          <v-row
                            v-if="category_assortment_id != ''"
                            dense
                            class="mx-4"
                          >
                            <v-col
                              v-for="(item, index) in assortmentproducts"
                              :key="index"
                              :cols="4"
                            >
                              <v-card class="h-100 mt-3 mb-3">
                                <v-img
                                  :src="
                                    item.main_image != null
                                      ? baseurl + item.main_image.url
                                      : '/media/product-channel.jpg'
                                  "
                                  gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                                  height="200px"
                                >
                                </v-img>
                                <v-card-text class="text--primary">
                                  {{ item.name }}
                                </v-card-text>

                                <v-card-actions>
                                  Product : {{ item.part_id }}
                                  <v-spacer> </v-spacer>
                                  <v-btn
                                    @click="
                                      $router.push(
                                        '/pim/product-view/' + item.id
                                      )
                                    "
                                  >
                                    View
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-col>
                          </v-row>
                          <div class="row">
                            <b-pagination
                              v-model="treecurrentPage"
                              :total-rows="treetotalCount"
                              :per-page="treelimit"
                              style="margin: 30px auto;"
                            ></b-pagination>
                          </div>
                        </v-tab-item>
                        <v-tab-item>
                          <v-row>
                            <v-spacer></v-spacer>
                            <v-col cols="6">
                              <v-text-field
                                v-model="tablesearch"
                                append-icon="mdi-magnify"
                                label="Search"
                                single-line
                                hide-details
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-data-table
                            :headers="headers"
                            :items="listassortmentproducts"
                            hide-default-footer
                            :search="treetablesearch"
                          >
                            <template #item="row">
                              <tr>
                                <td>{{ row.index + 1 }}</td>
                                <td>{{ row.item.name }}</td>
                                <td>{{ row.item.part_id }}</td>
                                <td>
                                  <v-btn
                                    class="ma-2"
                                    tile
                                    outlined
                                    color="success"
                                    @click="
                                      $router.push(
                                        '/pim/product-view/' + row.item.id
                                      )
                                    "
                                  >
                                    View
                                  </v-btn>
                                </td>
                              </tr>
                            </template>
                          </v-data-table>
                          <div class="row mt-8 mb-8">
                            <b-pagination
                              v-model="listcurrentPage"
                              :total-rows="treetotalCount"
                              :per-page="listlimit"
                              style="margin: 0 auto;"
                            ></b-pagination>
                          </div>
                        </v-tab-item>
                      </v-tabs>
                    </v-tab-item>
                  </v-tabs>
                </div>
                <div class="col-lg-4"></div>
              </div>
            </v-container>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
import AssortmentProducts from "@/graphql/queries/AssortmentProducts.gql";
import ListAssortmentProducts from "@/graphql/queries/ListAssortmentProducts.gql";

export default {
  data: () => ({
    showTreeview: false,
    listlimit: 10,
    listcurrentPage: 1,
    headers: [
      {
        text: "#",
        sortable: false,
      },
      {
        text: "Product Name",
        value: "name",
      },
      {
        text: "Part #",
        value: "part_id",
      },
      {
        text: "Actions",
        sortable: false,
      },
    ],
    treetablesearch: "",
    treelimit: 12,
    treecurrentPage: 1,
    treetotalCount: 0,
    tablesearch: "",
    productlimit: 12,
    productcurrentPage: 1,
    producttotalCount: 0,
    baseurl: process.env.VUE_APP_STRAPI_API_URL || "http://localhost:1337",
    activeChannel: "",
    channels: [],
    cards: [
      {
        title: "Shirts",
        src: "/media/stock-600x600/img-1.jpg",
        flex: 6,
      },
      {
        title: "Pants",
        src: "/media/stock-600x600/img-22.jpg",
        flex: "lg-3",
      },
    ],
    products: [],
    open: ["public"],
    files: {
      html: "mdi-language-html5",
      js: "mdi-nodejs",
      json: "mdi-json",
      md: "mdi-markdown",
      pdf: "mdi-file-pdf",
      png: "mdi-file-image",
      txt: "mdi-file-document-outline",
      xls: "mdi-file-excel",
    },
    tree: [],
    items: [
      {
        name: "Shirts",
        children: [
          {
            name: "Mens",
          },
          {
            name: "Womens",
          },
        ],
      },
      {
        name: "Pants",
      },
      {
        name: "Hats",
      },
      {
        name: "Coats",
      },
      {
        name: "Rain Coats",
      },
      {
        name: "Socks",
      },
      {
        name: "Sunglasses",
      },
      {
        name: "Vests",
      },
    ],
    category_assortment_id: "",
  }),
  apollo: {
    assortmentproducts: {
      query: AssortmentProducts,
      variables() {
        return {
          assortment: parseInt(this.category_assortment_id),
          limit: this.productlimit,
          start: (this.productcurrentPage - 1) * this.productlimit,
        };
      },
    },
    listassortmentproducts: {
      query: ListAssortmentProducts,
      variables() {
        return {
          assortment: parseInt(this.category_assortment_id),
          limit: this.listlimit,
          start: (this.listcurrentPage - 1) * this.listlimit,
        };
      },
    },
  },
  computed: {
    paginatedProducts() {
      let temp_products = this.products;
      var index = (this.productcurrentPage - 1) * this.productlimit;
      return temp_products.slice(index, index + this.productlimit);
    },
  },
  created() {
    if (this.$route.params.id != "" && this.$route.params.id != undefined) {
      setTimeout(() => {
        this.getChannelAccessWhere({
          where:
            "?_where[0][channel.supplier]=" +
            this.$route.params.id +
            "&_where[1][seller_company]=" +
            this.$store.state.auth.user.company_relation,
        }).then((res) => {
          this.channels = res.data;
          if (this.channels.length > 0) {
            this.activeChannel = this.channels[0].channel.id;
            this.getCategoryList();
          }
        });
      }, 1000);
    }
  },
  methods: {
    ...mapActions([
      "submitCategory",
      "getCategories",
      "removeCategory",
      "getChannelById",
      "getCategoryById",
      "updateCategory",
      "getChannelAccessTotalCount",
      "submitChannelAccess",
      "getChannelAccessByChannel",
      "removeChannelAccess",
      "getAssortmentsBySupplier",
      "getAllSellerApplications",
      "getAssortmentProductTotalCount",
      "getChannelAccessWhere",
      "getProductByAssortment",
    ]),
    tabChange(e) {
      if (e == 1) {
        this.showTreeview = true;
      } else {
        this.showTreeview = false;
      }
    },
    channelChange() {
      this.category_assortment_id = "";
      this.getCategoryList();
    },
    async getCategoryList() {
      if (this.activeChannel != "" && this.activeChannel != undefined) {
        let res = await this.getCategories({
          channel: this.activeChannel,
        });
        this.items = this.list_to_tree(res.data);
        this.products = [];
        this.producttotalCount = 0;
        this.productcurrentPage = 1;
        let ids = [];
        for (let i in res.data) {
          if (
            res.data[i].assortment != null &&
            res.data[i].assortment != undefined
          ) {
            let resp = await this.getProductByAssortment({
              assortment: res.data[i].assortment.id,
            });
            for (let j in resp.data) {
              if (ids.indexOf(resp.data[j].product.id) == -1) {
                ids.push(resp.data[j].product.id);
                this.products.push(resp.data[j].product);
              }
            }
          }
        }
        this.producttotalCount = this.products.length;
      }
    },
    list_to_tree(list) {
      var map = {};
      var node;
      var roots = [];
      var i;
      for (i = 0; i < list.length; i += 1) {
        map[list[i].id] = i; // initialize the map
        list[i].children = []; // initialize the children
      }
      for (i = 0; i < list.length; i += 1) {
        node = list[i];
        if (node.parent_id !== 0 && node.parent_id !== "0") {
          // if you have dangling branches check that map[node.parent_id] exists
          if (list[map[node.parent_id]] != undefined)
            list[map[node.parent_id]].children.push(node);
        } else {
          roots.push(node);
        }
      }
      return roots;
    },
    onUpdate(tree) {
      this.activatedId = tree;
      if (this.activatedId[0] !== undefined) {
        this.getCategoryById({
          id: this.activatedId[0],
        }).then((res) => {
          if (res.data.assortment != null) {
            this.category_assortment_id = res.data.assortment.id;

            this.treetotalCount = 0;
            this.getAssortmentProductTotalCount({
              params: "assortments=" + res.data.assortment.id,
            }).then((res) => {
              this.treetotalCount = res.data;
            });
          } else {
            this.category_assortment_id = "";
          }
        });
      } else {
        this.treetotalCount = 0;
        this.category_assortment_id = "";
      }
    },
  },
};
</script>
